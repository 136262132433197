import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

var unified = require('unified')
var markdown = require('remark-parse')
var remark2rehype = require('remark-rehype')
var html = require('rehype-stringify')
var format = require('rehype-format')


const convert = (input) => {
  var processor = unified()
    .use(markdown)
    .use(remark2rehype)
    .use(format)
    .use(html)
  return processor.processSync(input).toString()
}

const IndexPage = ({ data }) => {
  let pages = []
  for (let i = 0; i < data.allPosts.edges.length; i++) {
    let node = data.allPosts.edges[i].node;
    let uri = '';
    if (node.permalink !== "") {
      uri = `/${node.permalink}`
    } else {
      uri = `/${node.id}`;
    }

    pages.push(<Post>
      <Link to={uri}>{node.title}</Link>
      <div dangerouslySetInnerHTML={{ __html: convert(node.body) }}></div>
    </Post>)
  }
  return (
    <Layout>
      <SEO title="Home" />
      {pages}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
{
  allPosts(filter: {publicStatus: {eq: "public"}}, limit: 2, sort: {order: DESC, fields: id}) {
    edges {
      node {
        id
        title
        permalink
        publicStatus
        body
      }
    }
  }
}
`